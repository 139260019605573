@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

$bg-color 	: #ffcc00;
$bg-color2 	: white;

html { scroll-behavior: smooth; }
.fs-social-menu {
	z-index : 1;

	ul { 
		list-style 	: none; 
		padding 	: 0;
		position 	: relative; 

		ul { display : none; }
		>li.active { ul { display : inline-block; } }

		.fsm-btn {
			align-items 		: center; 
			box-shadow 			: 0px 0px 5px 0px rgba(0,0,0,0.75);
			display 		 	: flex;
			flex-direction 		: column;
			height  		 	: 60px;
			justify-content 	: center; 
			margin-bottom 		: .5rem; 
			width  			 	: 60px;

			span {
				font-size 	: 0.8em;
    			padding-top : 0.3rem;
    		}

			&:hover,
			&:focus,
			&:active {
				color 				: inherit;
				cursor 				: pointer; 
				outline 		 	: none; 		
			}
		}
		li.fsm-btn { 
			background-color : $bg-color; 
			&:hover,
			&:focus,
			&:active { background-color : darken($bg-color, 20%); }
		}
		a.fsm-btn { 
			background-color : $bg-color2; 
			&:hover,
			&:focus,
			&:active { background-color : darken($bg-color2, 20%); }
		}
		.social {
			bottom 		: 100%;
			position 	: absolute;
		}
	}
	
	a { color : inherit; }
	.container-actions {
		display 	: inline-block;
		position 	: fixed;
		right 		: 0;
		top  		: 80%;
		transform 	: translatey(-50%);

		transition 	: all .3s ease; 
	}
	.container-contact {
		display 			: inline-block;
		background-color 	: $bg-color;
		height 				: 100vh;
		left 				: 100%;
		max-width 			: 360px;
		padding 			: 4rem 2rem; 
		position 			: fixed;
		width 				: 90%;

		z-index : 2;

		transition: all .3s ease;

		>i { 
			position 	: absolute; 
			right 		: 2rem;
			top  		: 2rem;
		}

		hr { 
			border-top-color 	: black;  
			margin-bottom 		: 2rem;
		}

		li { margin-bottom : 1rem; }
	}
	.container-contact-overlay {
		background-color 	: black;
		display 			: none;
		height 				: 100vh;
		left 				: 0;
		opacity 			: 0;
		position 			: fixed;
		transition 			: opacity .3s ease .2s;
		width 				: 100%;
		z-index 			: 1;
	}
	&.contact {
		.container-contact { transform : translateX(-100%); }
		.container-contact-overlay { 
			display : inline-block;
			opacity : .8;
		}
	}
	.btn-toTop {
		display 	: none;
		max-height  : 0;
		opacity 	: 0;
		overflow 	: hidden; 

		transition 	: max-height .3s ease, opacity .3s ease .2s;

		&.show {
			display 	: flex;
			max-height 	: unset;
			opacity 	: 1;
		}
	}

	@include media-breakpoint-up(md) {
		.container-actions { top : 50% };
	}
}